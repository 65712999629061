class StickyAdvancedFilters {
  static async init () {
    return new StickyAdvancedFilters()
  }

  constructor () {
    const $filters = $('.sticky-filter')
    const $navigationBar = $('.sticky-nav')
    const $header = $('.site-header')

    if (!$filters.length || $(document).width() <= 992) return

    const filtersHeight = $filters.outerHeight()
    const windowHeight = $(window).height()
    const filtersOffset = $filters.offset().top

    $(window).on('scroll', () => {
      const navBarHeight = $navigationBar.length ? $navigationBar.outerHeight() + 16 : 16
      const availableHeight = windowHeight - navBarHeight
      const filtersTop = window.pageYOffset >= filtersOffset
      const matchFiltersHeight = filtersTop ? windowHeight - navBarHeight - 16 : windowHeight - $header.outerHeight() - 32

      if (filtersHeight < availableHeight) {
        $filters.addClass('sticky').css({ top: Math.floor(navBarHeight), height: Math.floor(matchFiltersHeight) })
      }
    }).trigger('scroll')
  }
}
export default StickyAdvancedFilters
